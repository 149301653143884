export const DialogTypes = {
  Confirm: 'Confirm',
  Policy: 'Policy',
  Pledge: 'Pledge',
  Financial: 'Financial',
  OfficeTerms: 'OfficeTerms',
  Approval: 'Approval',
  Terms: 'Terms',
  EditEntityPolicyApproval: 'EditEntityPolicyApproval',
  AnswersTerms: 'AnswersTerms',
  Council: 'Council',
  AppointeeDeceleration: 'AppointeeDeceleration',
  AppointeePledge: 'AppointeePledge',
  Document: 'Document',
  CloseDocument: 'CloseDocument',
  Acknowledgment: 'Acknowledgment',
  BankPermit: 'BankPermit',
  PriorAgreement: 'PriorAgreement',
  FamilyDisclosureTerms: 'FamilyDisclosureTerms',
  FamilyFundsTerms: 'FamilyFundsTerms',
  EditAvatar: 'EditAvatar',
  FamilyFundsAgreementsModal: 'FamilyFundsAgreementsModal',
  FamilyFundsAgreementsJoinModal: 'FamilyFundsAgreementsJoinModal',
  DelegateAcknowledgement: 'DelegateAcknowledgement',
  AgreementsDelegateModal: 'AgreementsDelegateModal',
};

export const LoginSteps = {
  Verification: 'Verification',
  Confirmation: 'Confirmation',
};

export const RegistrationSteps = {
  Authentication: 'Authentication',
  Sms: 'Sms',
  Phone: 'Phone',
  Password: 'Password',
};

export const ResetPasswordSteps = {
  Authentication: 'Authentication',
  Sms: 'Sms',
  Password: 'Password',
};

export const UserRoles = {
  Individual: 'individual',
  Delegate: 'delegate',
};

export const UserGender = {
  Male: 'M',
  Female: 'F',
};

export const EntityTypes = {
  CooperativeAssociation: 'cooperativeAssociation',
  PrivateAssociation: 'privateAssociation',
  PrivateCorporation: 'privateCorporation',
  FamilyFund: 'familyFund',
};

export const MembershipSteps = {
  Info: 'Info',
  Reason: 'Reason',
};

export const UserRequestStatuses = {
  Draft: 'draft',
  Pending: 'pending',
  AcceptedByUser: 'accepted_by_user',
  RejectedByUser: 'rejected_by_user',
  AcceptedBySpecialized: 'accepted_by_specialized_admin',
  RejectedBySpecialized: 'rejected_by_specialized_admin',
  FinalRejected: 'final_rejected',
  DidNotRespond: 'did_not_respond',
  Canceled: 'canceled',
};

export const BranchMemberTypes = {
  New: 'new',
  Individual: 'new',
  Existing: 'existing',
  Employee: 'employee',
};

export const MemberTypes = {
  Individual: 'Individual',
  Existing: 'Existing',
  Delegate: 'Delegate',
  Employee: 'Employee',
};

export const ClassificationsTypes = {
  MainClassification: 'MainClassification',
  FirstSubClassification: 'FirstSubClassification',
  SecondSubClassification: 'SecondSubClassification',
};

export const JoinSteps = {
  Delegate: 'Delegate',
  About: 'About',
  Security: 'Security',
  Disclosure: 'Disclosure',
};

export const CreatePermitSteps = {
  CreateForm: 'CreateForm',
  Acknowledgment: 'Acknowledgment',
  PermitInfo: 'PermitInfo',
};

export const CreatePermitTypes = {
  Main: 'main_account',
  Branch: 'branch_account',
  Expenses: 'branch_expenses_account',
};

export const FamilySteps = {
  Name: 'Name',
  Disclosure: 'Disclosure',
  Qualification: 'Qualification',
  Location: 'Location',
  Address: 'Address',
  Goals: 'Goals',
  Subscription: 'Subscription',
  Conditions: 'Conditions',
  AddMember: 'AddMember',
  Summary: 'Summary',
  Agreements: 'Agreements',
  // Bank: 'Bank',
};

export const EntitySteps = {
  Type: 'Type',
  Representation: 'Representation',
  Classification: 'Classification',
  MembershipTypes: 'MembershipTypes',
  Activities: 'Activities',
  Eligibility: 'Eligibility',
  // Financial: 'Financial',
  Location: 'Location',
  Ceo: 'Ceo',
  MembershipRequirements: 'MembershipRequirements',
  BoardTermLength: 'BoardTermLength',
  // Bank: 'Bank',
  Individual: 'Individual',
  Security: 'Security',
  Disclosure: 'Disclosure',
  OwnerMember: 'OwnerMember',
  MemberType: 'MemberType',
  Member: 'Member',
  Summary: 'Summary',
  Resources: 'Resources',
};

export const RequestTypes = {
  EntityEstablishment: 'entity_establishment',
  BranchCooperativeAssociation: 'initiate_branch_cooperative_association',
  BranchPrivateAssociation: 'initiate_branch_private_association',
  BranchPrivateCorporation: 'initiate_branch_private_corporation',
  CreateActivity: 'create_activity',
  AppointingEmployee: 'appointing_employee',
  EditPolicyGoalsAndActivities: 'goals_and_activities',
  EditPolicyRegulationAndGeographic: 'regulation_items_and_geografic_information',
};

export const RequestStatuses = {
  Draft: 'draft',
  MemberApproval: 'member_approval',
  MemberAdjustments: 'member_adjustments',
  AdminsApproval: 'admins_approval',
  AdminEdit: 'registration_and_permit_admin_edit',
  SupervisoryApproval: 'supervisory_approval',
  SupervisoryAdjustments: 'supervisory_adjustments',
  AcceptedByHrsdAdmin: 'accepted_by_hrsd_admin',
  AcceptedBySupervisoryAdmin: 'accepted_by_supervisory_admin',
  OpenBankAccount: 'temp_bank_account_opening',
  UpdatingFinancialInfo: 'updating_financial_info',
  HrsdEmployeeApproval: 'hrsd_employee_approval',
  SubmittedFinancialInfo: 'submitted_financial_info',
  CloseBankAccount: 'temp_bank_account_closing',
  TempBankAccountClosing: 'temp_bank_account_closing',
  Accepted: 'accepted',
  Rejected: 'rejected',
  RequestResults: 'request_results',
  RegistrationAndPermitApproval: 'registration_and_permit_approval',
};

export const FileErrorTypes = {
  Size: 'size',
  Extension: 'extension',
  Request: 'request',
};

export const EntityClass = {
  Entity: 'entity',
  Branch: 'branch',
};

export const MembershipEntityTypes = {
  Entity: 'Entity',
  Branch: 'Branch',
};

export const UnitTypes = {
  Entity: 'Entity',
  Branch: 'Branch',
};

export const RequestTabs = {
  Entity: 'Entity',
  Branch: 'Branch',
  Employee: 'Employee',
};

export const DocumentItemType = {
  Entity: 'entity',
  Branch: 'branch',
  Activity: 'entity_activity',
  BankAccountPermit: 'bank_account_permit',
  BankAccount: 'bank_account',
};

export const DocumentType = {
  License: 'license',
  PermitEstablishBoard: 'permit_to_establish_board',
  BankAccountPermit: 'bank_account_permit',
  BankAccount: 'bank_account',
  WorkPermit: 'work_permit',
  Policy: 'policy',
  BankPermit: 'temporary_bank_account_permit',
  BankContract: 'establishment_contract',
  EstablishmentDecision: 'establishment_decision',
  CloseBankAccount: 'closing_bank_account_letter',
  ApprovalActivity: 'activity_approval',
  PermitBankAccount: 'permit_to_open_bank_account',
  PermitRequireDocuments: 'required_documents_list',
  EntityLogo: 'entity_logo',
  ReactivateBankAccount: 'bank_account_reactivation',
};

export const NotificationStatus = {
  Pending: 'pending',
  Delivered: 'delivered',
  Read: 'read',
};

export const PermitPositions = [
  'financial_supervisor',
  'financial_supervisor_1st_reserve',
  'financial_supervisor_2nd_reserve',
];

export const mediaAttachableTypes = {
  Branch: 'Branch',
  Entity: 'Entity',
  GovernmentalEntity: 'GovernmentalEntity',
  User: 'User',
  Membership: 'Membership',
  EntityPolicyRequest: 'EditRequest',
  EntityActivity: 'EntityActivity',
  DelegatorProof: 'DelegatorProof',
};

export const mediaDocTypes = {
  ApprovalFile: 'approval_file',
  BankCertificate: 'bank_certificate',
  BankVerificationFile: 'iban_verification_file',
  BankVerificationBranchFile: 'bank_verification_file',
  Certificate: 'certificate',
  EligibilityStudyFile: 'eligibility_study_file',
  GovernmentalFile: 'governmental_file',
  EntityAcknowledgmentFile: 'entity_acknowledgment_file',
  ProofFile: 'proof_file',
  SpecializedAdminFile: 'specialized_admin_file',
  ReferenceTemplates: 'reference_templates',
  GeneralAssemblyFile: 'general_assembly_file',
  TempBankStatement: 'temp_bank_account_statement_file',
  TempBankCertificate: 'temp_bank_account_certificate_file',
  ConfirmationOfClose: 'temp_bank_account_confirmation_of_close_file',
  ProofRegisteredForEntity: 'entity_proof_financial_commitment_500k_file',
  DelegatorProofLetter: 'delegator_proof_letter',
  ContributionProof: 'contribution_proof',
  EducationalCertificate: 'educational_certificate',
  SupportingDocument: 'supporting_document',
  LicenseProof: 'non_profit_organization_license_proof',
  CommercialProof: 'commercial_registration_number_proof',
  EndowmentProof: 'endowment_deed_number_proof',
  GosiProof: 'gosi_number_proof',
  ZakatProof: 'zakat_certificate_number_proof',
  Number700: 'facility_record_number_proof',
  RespondQuestion: 'respond_file',
  AssociationMinutesProof: 'association_minutes_proof',
  EntityApprovalProof: 'entity_approval_proof',
  EstablishmentApprovalProof: 'establishment_approval_proof',
};

export const LocaleTypes = {
  En: 'en',
  Ar: 'ar',
};

export const EditEntityPolicyTabset = {
  PrimaryInformationTab: 'PrimaryInformationTab',
  RegulationItemsAndGeographicInformationTab: 'RegulationItemsAndGeographicInformationTab',
  GoalsAndActivitiesTab: 'GoalsAndActivitiesTab',
};

export const EntityBankTypes = {
  Main: 'entity_main',
  Secondary: 'entity_secondary',
  Expense: 'entity_expenses',
};

export const BranchBankTypes = {
  Branch: 'branch',
  Expense: 'branch_expenses',
};

export const EmployeeRoles = {
  Ceo: 'ceo',
  Accountant: 'accountant',
  Other: 'other',
};

export const EmployeeRequestStatuses = {
  Draft: 'draft',
  Pending: 'pending',
  AcceptedByUser: 'accepted_by_user',
  RejectedByUser: 'rejected_by_user',
  AcceptedBySpecializedAdmin: 'accepted_by_specialized_admin',
  RejectedBySpecializedAdmin: 'rejected_by_specialized_admin',
  AcceptedByHrsdEmployee: 'accepted_by_hrsd_employee',
  RejectedByHrsdEmployee: 'rejected_by_hrsd_employee',
  AcceptedByRegistrationAndPermitAdmin: 'accepted_by_registration_and_permit_admin',
  RejectedByRegistrationAndPermitAdmin: 'rejected_by_registration_and_permit_admin',
  Accepted: 'accepted',
  Rejected: 'rejected',
};

export const EmployeeRequestTracker = {
  EmployeeApproval: 'employee_approval',
  AdminsApproval: 'admins_approval',
  RequestResults: 'request_results',
};

export const EditRequestTypes = {
  PrimaryInformation: 'primary_information',
  RegulationItemsAndGeographicInformation: 'regulation_items_and_geografic_information',
  GoalsAndActivities: 'goals_and_activities',
  EntityAdjustmentsBySupervisory: 'entity_establishment_supervisory_changes',
};

export const EditRequestUnitTypes = {
  Entity: 'Entity',
  EntityLocation: 'EntityLocation',
  MembershipCondition: 'MembershipCondition',
  FamilyFundSubscription: 'FamilyFundSubscription',
  EntitiesBank: 'EntitiesBank',
  EntityNationalAddress: 'EntityNationalAddress',
};

export const EditRequestUnitActions = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete',
  Idle: 'idle',
};

export const EditPolicyStatuses = {
  Draft: 'draft',
  Pending: 'pending',
  Rejected: 'rejected',
  Accepted: 'accepted',
};

export const RequestMode = {
  History: 'history',
  Actual: 'actual',
};

export const PolicyRequestApprovalSteps = {
  Draft: 'draft',
  AdminsApproval: 'admins_approval',
  RequestResults: 'request_results',
};

export const PolicyRequestApprovalStatuses = {
  Draft: 'draft',
  Pending: 'pending',
  RegistrationAndPermitApproval: 'registration_and_permit_approval',
  SupervisoryApproval: 'supervisory_approval',
  Accepted: 'accepted',
  Rejected: 'rejected',
  Adjustment: 'adjustment',
};

export const TemporaryBankAccountSteps = {
  EntityInfo: 'EntityInfo',
  CreateBankAccount: 'CreateBankAccount',
  SummaryMembersStep: 'SummaryMembersStep',
};

export const DatePattern = {
  General: 'dd/MM/yyyy',
  Rtl: 'yyyy/MM/dd',
  Full: 'dd/MM/yyyy hh:mm',
};
export const TimePart = {
  Seconds: 'seconds',
  Minutes: 'minutes',
  Hours: 'hours',
};

export const RequestsTypeNames = {
  RequestEntity: 'requestEntity',
  RequestBranch: 'requestBranch',
  RequestActivity: 'requestActivity',
  RequestEmployee: 'requestEmployee',
  RequestPolicy: 'requestPolicy',
};

export const nonGovernmentalTypes = {
  License: 'NonProfitOrganizationLicense',
  Endowment: 'EndowmentDeedNumber',
  Commercial: 'CommercialRegistrationNumber',
  PrivateAssociation: 'PrivateAssociation',
  PrivateCorporation: 'PrivateCorporation',
};

export const MembershipTypeAssociation = {
  Closed: 'closed',
  Open: 'open',
};

export const MembershipTypesOptions = {
  Worker: 'worker',
  Associate: 'associate',
  Honorary1: 'honorary1',
  Honorary2: 'honorary2',
};

export const MembersReplacementTypes = {
  SpecializedReplacement: 'specialized_replacement',
  None: 'none',
};

export const SecondApprovalTypes = {
  None: 'none',
  Specialized: 'second_specialized_approval',
  RegistrationAndPermit: 'second_registration_and_permit_approval',
};

export const EntityCardsTypes = {
  EntityMember: 'entityMember',
  EntityOwner: 'entityOwner',
  BranchOwner: 'branchOwner',
};

export const PublicDocType = {
  License: 'License',
};

export const PublicItemType = {
  Entity: 'Entity',
};

export const LicenseValidationStatus = {
  Valid: 'accepted',
  Invalid: 'invalid',
  Expired: 'expired',
};

export const ServiceCategories = {
  Registration: 'registration',
  Supervision: 'supervision',
  Support: 'support',
};

export const MemberRole = {
  Owner: 'Owner',
  Individual: 'Individual',
  Delegate: 'Delegate',
  Guest: 'Guest',
};

export const UserRequestPayloadType = {
  FamilyFundCreate: 'FamilyFundCreate',
  FamilyFundUpdate: 'FamilyFundUpdate',
  EntityCooperativeAssociationUpdate: 'EntityCooperativeAssociationUpdate',
  EntityPrivateAssociationUpdate: 'EntityPrivateAssociationUpdate',
  EntityPrivateCorporationUpdate: 'EntityPrivateCorporationUpdate',
  EntityCooperativeAssociationCreate: 'EntityCooperativeAssociationCreate',
  EntityPrivateAssociationCreate: 'EntityPrivateAssociationCreate',
  EntityPrivateCorporationCreate: 'EntityPrivateCorporationCreate',
  BranchCreate: 'BranchCreate',
};

export const MemberInvitationSteps = {
  UserInfo: 'UserInfo',
  Disclosure: 'Disclosure',
};

export const featureNames = {
  createCooperativeEntity: 'FEATURE_CREATE_COOPERATIVE_ENTITY',
  appointingCharteredAccountantOffice: 'FEATURE_APPOINTING_CHARTERED_ACCOUNTANT_OFFICE',
  eshopLicense: 'FEATURE_ESHOP_LICENSE',
  beneficiaryManagement: 'FEATURE_BENEFICIARY_MANAGEMENT',
  inspectionVisits: 'FEATURE_INSPECTION_VISITS',
  manageMeetings: 'FEATURE_MANAGE_MEETINGS',
  generalAssociationMeetings: 'FEATURE_GENERAL_ASSOCIATION_MEETINGS',
  notifications: 'FEATURE_NOTIFICATIONS',
  landingArabicLocaleOnly: 'FEATURE_LANDING_ARABIC_LOCALE_ONLY',
  officeSpa: 'FEATURE_OFFICE_SPA',
  hotkeys: 'FEATURE_HOTKEYS',
  entityPolicyService: 'FEATURE_ENTITY_POLICY_SERVICE',
  unifiedNumberFilter: 'FEATURE_UNIFIED_NUMBER_FILTER',
};

export const EditRequestTypesByAdminRole = {
  registration_and_permit_admin: 'ffe_establishment_rp_admin_changes',
  registration_and_permit_employee: 'ffe_establishment_rp_employee_changes',
  registration_and_permit_ceo: 'ffe_establishment_rp_ceo_changes',
};

export const RemainingType = {
  Day: 'd',
  Min: 'min',
};

export const AnnouncementType = {
  Initial: 'Initial',
  Final: 'Final',
};

export const EmployeeHistoryStatuses = [
  EmployeeRequestStatuses.Rejected,
  EmployeeRequestStatuses.Accepted,
];

export const DelegateSteps = {
  TypeStep: 'TypeStep',
  ReplaceStep: 'ReplaceStep',
};

export const DelegateRoles = {
  ExistingMember: 'Internal',
  PreviousDelegate: 'PrevDelegate',
  NewMember: 'External',
};

export const MembershipRequestStatus = {
  Draft: 'draft',
  Pending: 'pending',
  Accepted: 'accepted',
  Rejected: 'rejected',
  Canceled: 'canceled',
};

export const MembershipRequestUnitTypes = {
  Entity: 'Entity',
};

export const ActionExpectationTypes = {
  ReviewEdit: 'review_edit_request',
  RespondQuestion: 'respond_to_question',
};

export const TranslationsForTitles = {
  Ar: 'arTitle',
  En: 'enTitle',
};

export const AnnouncementTypes = {
  Initial: 'Initial',
  Final: 'Final',
};

export const FamilyFundTypes = {
  Self: 'self',
  Affiliated: 'affiliated',
  General: 'general',
};

export const notAllowedPositions = [
  'financial_supervisor',
  'financial_supervisor_1st_reserve',
  'financial_supervisor_2nd_reserve',
  'chairman_1st_reserve',
  'chairman_2nd_reserve',
  'vice_chairman_1st_reserve',
  'vice_chairman_2nd_reserve',
];

export const Departments = {
  HumanResourcesMinistry: 7,
};
