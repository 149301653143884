import Vue from 'vue';
import Vuex from 'vuex';
import adjustmentsModule from './adjustments';
import authModule from './auth';
import bankAccountModule from './bank-account';
import documentsModule from './documents';
import editPolicyModule from './edit-policy';
import employeeModule from './employee';
import entitiesModule from './entities';
import entityModule from './entity';
import familyModule from './family-fund';
import lookupModule from './lookup';
import membershipModule from './membership';
import notificationsModule from './notifications';
import requestsModule from './requests';
import toastModule from './toast';
import uiModule from './ui';
import userModule from './user';
import userRequestsModule from './user-requests';
import userNotificationsModule from './user-notifications';
import entityServiceModule from './entity-service';
import editPolicyAdjustmensModule from './edit-policy-adjustments';
import entityProfileModule from './entity-profile';
import entityRelationsModule from './entity-relations';
import familyFundInvitationModule from './family-fund-invitation';
import editRequestModule from './edit-request';
import delegateManagementModule from './delegate-management';

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

const store = new Vuex.Store({
  strict: debug,
  modules: {
    adjustments: adjustmentsModule,
    auth: authModule,
    bankAccount: bankAccountModule,
    documents: documentsModule,
    editPolicy: editPolicyModule,
    employee: employeeModule,
    entities: entitiesModule,
    entity: entityModule,
    familyFund: familyModule,
    lookup: lookupModule,
    membership: membershipModule,
    notifications: notificationsModule,
    requests: requestsModule,
    toast: toastModule,
    ui: uiModule,
    user: userModule,
    userRequests: userRequestsModule,
    userNotifications: userNotificationsModule,
    entityService: entityServiceModule,
    editPolicyAdjustments: editPolicyAdjustmensModule,
    entityProfile: entityProfileModule,
    entityRelations: entityRelationsModule,
    familyFundInvitation: familyFundInvitationModule,
    editRequest: editRequestModule,
    delegateManagement: delegateManagementModule,
  },
});

window.logStoreMutations = () => {
  store.subscribe(({ type, payload }, state) => {
    console.log(
      `%c 🧟‍♀️ Mutation:   ${type} `,
      'background: #333; color: #bada55',
      {
        payload,
        state,
      },
    );
  });
};

window.logStoreActions = () => {
  store.subscribeAction(({ type, payload }, state) => {
    console.log(
      `%c 🎬 Action:     ${type} `,
      'background: #333; color: #54afbd',
      {
        payload,
        state,
      },
    );
  });
};

export default store;
